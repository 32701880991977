import { Box, BoxProps, Icon, IconButton, useBoolean } from '@chakra-ui/react';
import {
  FunctionComponent,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useStore } from 'zustand';
import { IconAudioOff, IconAudioOn, IconPlay } from '../../../Icons';
import { useCardStore } from '../../store';

export type AvatarVideoProps = BoxProps &
  HTMLAttributes<HTMLVideoElement> & {
    autoPlay?: boolean;
    hasAudioTrack?: boolean;
    loop?: boolean;
    poster?: string;
    sources: Array<{
      src: string;
      type: string;
    }>;
  };

export const AvatarVideo: FunctionComponent<AvatarVideoProps> = ({
  autoPlay = true,
  hasAudioTrack = false,
  loop = true,
  sources,
  ...props
}) => {
  const cardStore = useCardStore();
  const { variant, embeddedMobile } = useStore(cardStore, (state) => ({
    embeddedMobile: state.embeddedMobile,
    variant: state.variant,
  }));
  const [isVideoMuted, setIsVideoMuted] = useBoolean(true);
  const [isFocused, setIsFocused] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(autoPlay);

  const player = useRef<HTMLDivElement & HTMLVideoElement>(null);

  const key = useMemo(() => JSON.stringify(sources), [sources]);

  const handleFocusListener = useCallback((data: MessageEvent<any>) => {
    const messageParsed = JSON.parse(data.data);
    if (messageParsed.type === 'focus') {
      setIsFocused(messageParsed.isFocused);
    }
  }, []);

  useEffect(() => {
    if (embeddedMobile && variant === 'mini') {
      window.addEventListener('message', handleFocusListener);
      return () => {
        if (embeddedMobile && variant === 'mini') {
          window.removeEventListener('message', handleFocusListener);
        }
      };
    }
    return () => {};
  }, [embeddedMobile, handleFocusListener, variant]);

  useEffect(() => {
    if (variant !== 'mini' || !embeddedMobile) {
      return;
    }
    if (isFocused) {
      player.current?.play();
    } else if (isFocused === false) {
      player.current?.pause();
    }
  }, [embeddedMobile, isFocused, variant]);

  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  const handleVideoPlayToggle = () => {
    if (player.current?.paused) {
      player.current.play();
    } else {
      player.current?.pause();
    }
  };

  return (
    <>
      <Box
        {...props}
        key={key}
        ref={player}
        as="video"
        autoPlay={autoPlay}
        controls={false}
        cursor="pointer"
        h="full"
        loop={loop}
        muted={isVideoMuted}
        objectFit="cover"
        onClick={handleVideoPlayToggle}
        onPause={handleVideoPause}
        onPlay={handleVideoPlay}
        playsInline
        preload="auto"
        w="full"
      >
        {sources.map((source) => (
          <source {...source} key={source.src} />
        ))}
      </Box>

      {hasAudioTrack && isVideoPlaying && (
        <IconButton
          _hover={{ bg: 'rgba(0, 0, 0, 0.25)', opacity: 0.8 }}
          aria-label={isVideoMuted ? 'umute' : 'mute'}
          bg="rgba(0, 0, 0, 0.25)"
          borderRadius="full"
          className="AvatarVideo__AudioControls"
          h="12"
          icon={
            isVideoMuted ? (
              <IconAudioOff color="rgba(255, 255, 255, 0.8)" h="1.5rem" w="1.5rem" />
            ) : (
              <IconAudioOn color="rgba(255, 255, 255, 0.8)" h="1.5rem" w="1.5rem" />
            )
          }
          onClick={setIsVideoMuted.toggle}
          position="absolute"
          right="0.625rem"
          top="0.625rem"
          w="12"
        />
      )}

      {!isVideoPlaying && (
        <Box
          aria-label="play"
          h="12"
          left="50%"
          onClick={handleVideoPlayToggle}
          position="absolute"
          top="50%"
          transform="translateX(-50%) translateY(-50%)"
          w="12"
        >
          <Icon
            as={IconPlay}
            color="rgba(255, 255, 255, 0.8)"
            h="full"
            stroke="#333333"
            strokeOpacity="0.5"
            strokeWidth="0.625rem"
            w="full"
          />
        </Box>
      )}
    </>
  );
};
