import { hexToHsl } from '../../utils';

export const relightenColor = (color: string, light: number, opacity: number = 1) => {
  const [H, S] = hexToHsl(color);

  return `hsla(${H}, ${S}%, ${light}%, ${opacity})`;
};

type PaginationOption = { key: number; pageNumber: number; type: 'button' | 'placeholder' };

export type MaxPageOptions = 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export const computePaginationOptions = ({
  currentPage,
  maxPageOptions = 11,
  pages,
}: {
  currentPage: number;
  maxPageOptions?: MaxPageOptions;
  pages: number;
}): Array<PaginationOption> => {
  if (pages <= maxPageOptions) {
    return Array.from({ length: pages }, (_, i) => ({
      key: i,
      pageNumber: i + 1,
      type: 'button',
    }));
  }

  if (maxPageOptions === 3) {
    return [
      { key: 0, pageNumber: 1, type: 'button' },
      { key: 1, pageNumber: 2, type: 'placeholder' },
      { key: 2, pageNumber: pages, type: 'button' },
    ];
  }

  return Array.from({ length: maxPageOptions }, (_, i) => {
    if (i === 0 || i === maxPageOptions - 1) {
      return { key: i, pageNumber: i === 0 ? 1 : pages, type: 'button' };
    }

    const middleOptionIndex = Math.floor(maxPageOptions / 2);

    if (i === 1) {
      const type = currentPage > middleOptionIndex + 1 ? 'placeholder' : 'button';
      return { key: i, pageNumber: i + 1, type };
    }

    if (i === maxPageOptions - 2) {
      const type = currentPage < pages - middleOptionIndex ? 'placeholder' : 'button';
      return { key: i, pageNumber: pages - 1, type };
    }

    let pageNumber = i + 1;

    if (currentPage > middleOptionIndex) {
      if (currentPage > pages - middleOptionIndex) {
        pageNumber = pages - (maxPageOptions - i - 1);
      } else {
        pageNumber = currentPage + (i - middleOptionIndex);
      }
    }

    return {
      key: i,
      pageNumber,
      type: 'button',
    };
  });
};
