import { IconButton, IconButtonProps, Text, useToken } from '@chakra-ui/react';
import { IconChevronLeft, IconChevronRight } from '../Icons';
import { relightenColor } from './helpers';

export type PaginationButtonSize = 'sm' | 'md';

export type PaginationButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  brandColor?: string;
  isActive?: boolean;
  size?: PaginationButtonSize;
} & (
    | {
        isNextButton?: never;
        isPreviousButton: boolean;
        pageNumber?: never;
      }
    | {
        isNextButton: boolean;
        isPreviousButton?: never;
        pageNumber?: never;
      }
    | {
        isNextButton?: never;
        isPreviousButton?: never;
        pageNumber: number;
      }
  );

export const PaginationButton = ({
  brandColor,
  isActive,
  isPreviousButton,
  isNextButton,
  pageNumber,
  size = 'md',
  ...props
}: PaginationButtonProps) => {
  const [defaultBrandColor] = useToken('colors', ['violet.600']);

  const baseColor = brandColor || defaultBrandColor;
  const bgColor = relightenColor(baseColor, 90);
  const bgPseudoColor = isActive ? bgColor : relightenColor(baseColor, 95);
  let ariaLabel = `Got to page ${pageNumber}`;
  let dataTestId = `pagination-button-${pageNumber}`;

  let icon = (
    <Text fontSize="sm" fontWeight="semibold">
      {pageNumber}
    </Text>
  );

  if (isPreviousButton) {
    ariaLabel = 'Previous page';
    dataTestId = 'pagination-button-previous';
    icon = <IconChevronLeft />;
  } else if (isNextButton) {
    ariaLabel = 'Next page';
    dataTestId = 'pagination-button-next';
    icon = <IconChevronRight />;
  }

  return (
    <IconButton
      {...props}
      _active={{ bg: bgPseudoColor }}
      _hover={{ bg: bgPseudoColor }}
      aria-label={ariaLabel}
      bg={isActive ? relightenColor(baseColor, 90) : 'transparent'}
      borderRadius="base"
      color={isActive ? relightenColor(baseColor, 45) : undefined}
      data-testid={dataTestId}
      flexShrink="0"
      h={size === 'sm' ? 6 : 8}
      icon={icon}
      minW="0"
      w={size === 'sm' ? 6 : 8}
    />
  );
};
