var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8f2b3151d392cef57586fb8621db0bd73ccd9f24"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/auth";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { deployEnv } from '@src/lib/utils';

Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: 'https://7947139b0f314a1fa394ceecf31eae39@o1027395.ingest.sentry.io/4505393014505472',
  enabled: deployEnv === 'production',
  environment: deployEnv,

  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      blockAllMedia: true,
      maskAllText: true,
    }),
  ],

  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.05,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
});
