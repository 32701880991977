import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { Children, FunctionComponent, isValidElement } from 'react';

export type PaginationContainerProps = FlexProps & {
  isLoading?: boolean;
  loadingText?: string;
  resultsText?: string;
  resultsTextSize?: 'sm' | 'xs';
};

export const PaginationContainer: FunctionComponent<PaginationContainerProps> = ({
  children,
  isLoading,
  loadingText,
  resultsText,
  resultsTextSize = 'sm',
  ...flexProps
}) => {
  const child = Children.only(children);

  if (!isValidElement(child)) {
    throw new Error('Children of PaginationContainer must be of type Pagination');
  }

  if (resultsText) {
    return (
      <Flex alignItems="center" justifyContent="space-between" p={4} width="full" {...flexProps}>
        <Text
          color="gray.500"
          fontSize={resultsTextSize === 'xs' ? 'xs' : 'sm'}
          lineHeight={resultsTextSize === 'xs' ? '1.5rem' : '2rem'}
        >
          {resultsText}
        </Text>
        {children}
      </Flex>
    );
  }

  return (
    <Flex alignContent="center" justifyContent="flex-end" p={4} width="full" {...flexProps}>
      {isLoading ? loadingText || 'Loading...' : null}
      {children}
    </Flex>
  );
};
