import type { TableCellProps } from '@chakra-ui/react';
import { Td as ChakraTd } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

const headerBorder = (withTopBorder: boolean = false) =>
  `inset 0 ${withTopBorder ? 1 : 0}px 0 0 var(--chakra-colors-gray-200)`;

export type TdProps = TableCellProps & {
  withTopBorder?: boolean;
};

export const Td: FunctionComponent<TdProps> = ({ children, withTopBorder, ...tableCellProps }) => (
  <ChakraTd borderBottom="none" boxShadow={headerBorder(withTopBorder)} {...tableCellProps}>
    {children}
  </ChakraTd>
);
